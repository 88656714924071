@import '../../styles/variables.scss';

.floating-button {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  box-shadow: $button-shadow;
  color: #ffffff;
  background: map-get($map: $colors, $key: 'brand');
  font-size: 1rem;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all .2s ease-out;

  &:hover {
    background: darken(map-get($map: $colors, $key: 'brand'), 5);
  }
}