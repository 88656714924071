@import '../../styles/variables.scss';

div.jsoneditor {
  height: 40vh;
  border: none;
}

div.jsoneditor-tree {
  border: 1px solid map-get($map: $colors, $key: 'utility');
  border-top: none;
  border-radius: 0 0 $border-radius $border-radius;
}

div.jsoneditor-navigation-bar {
  border-left: 1px solid map-get($map: $colors, $key: 'utility');
  border-right: 1px solid map-get($map: $colors, $key: 'utility');
}

div.jsoneditor-menu {
  border: none;
  background-color: map-get($map: $colors, $key: 'brand');
  border-radius: $border-radius $border-radius 0 0;
}

div.jsoneditor-menu>button, div.jsoneditor-menu>div.jsoneditor-modes>button {
  opacity: 1;
}

table.jsoneditor-search {
  display: none;
}