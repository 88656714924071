@import '../../styles/variables.scss';

.page-title {
  width: 100%;
  min-height: 55px;
  height: 55px;
  background: #ffffff;
  padding: 0 3rem 0 1rem;
  display: flex;
  align-items: center;
  box-shadow: $box-shadow;
  z-index: 9;

  div:last-child {
    margin-left: auto;
  }

  .title {
    font-size: 1.25rem;
  }

  .breadcrumbs {
    display: flex;

    a {
      color: map-get($map: $colors, $key: 'brand');

      &:after {
        margin: 0 0.25rem;
        content: '/';
        color: map-get($map: $colors, $key: 'text-light');
      }
    }
  }
}