.company-roles-wrapper {
  margin-top: 1rem;

  .company-roles {
    display: flex;
    flex-wrap: wrap;

    .checkbox {
      min-width: 25%;
      width: 25%;
    }
  }
}
