@import url('https://fonts.googleapis.com/css?family=Montserrat|Roboto&display=swap');
@import './variables.scss';

body, html {
  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;
  color: map-get($map: $colors, $key: 'text');
  line-height: 1.25;

  * {
    box-sizing: border-box;
    
    &:focus {
      outline:0;
    }
  }
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 0.8rem;
}

.text-error {
  color: map-get($map: $colors, $key: 'error');
}

.full-size-container {
  width: 100vw;
  height: 100vh;
  background: map-get($map: $colors, $key: 'background');
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  @extend .flex;
  justify-content: center;
  align-items: center;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1.5rem 2rem;
  overflow-y: auto;
  flex: 1;
}

.two-column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 49%;
  }
}

.icon-success {
  color: map-get($map: $colors, $key: 'success');
}

.icon-error {
  color: map-get($map: $colors, $key: 'error');
}

.text-link {
  color: map-get($map: $colors, $key: 'brand');
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.dropdown-heading{
  border: 1px solid #acacac !important;
}

.dropdown-heading-dropdown-arrow{
  span {
    color: #242424 !important;

  }
}

.dropdown-heading-value {
  span {
    color: #242424 !important;
  }
}