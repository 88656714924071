@import '../../../styles/variables.scss';

.login-help {
  color: map-get($map: $colors, $key: 'brand');
  margin-top: 2rem;
}

.utility-links {
  a:hover {
    text-decoration: underline;
  }
}