@import '../../styles/variables.scss';

.spinner {
  display: flex;
  justify-content: space-between;

  &.light {
    & div {
      background: #ffffff;
    }
  }

  &.dark {
    & div {
      background: map-get($map: $colors, $key: 'brand');
    }
  }

  div {
    height: 100%;
    flex: 1;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1px;
    }
    
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  @for $i from 2 through 5 {
    .rect#{$i} {
      animation-delay: -1.1 + ($i - 2) * 0.1s;
    }
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
  }
}