@import '../../styles/variables.scss';

.notifications-container {
  z-index: 9999;
  width: 100vw;
  position: fixed;
  top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  .notification {
    display: flex;
    align-items: center;
    height: 50px;
    width: 400px;
    font-size: 0.8rem;
    padding: 0 1rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    opacity: 1;
    transform: translateY(0);
    transition: all .5s ease, top .4s ease-out;
    animation: slide-down .5s ease;
    position: absolute;
    top: 0;

    &.fade {
      opacity: 0;
      transform: translateY(20px);
    }
    &.success {
      background: map-get($map: $colors, $key: 'success');
    }
    &.error {
      background: map-get($map: $colors, $key: 'error');
    }
    &.info {
      background: map-get($map: $colors, $key: 'brand');
    }

    .message {
      flex: 1;
      color: #ffffff;
    }

    .notification-dismiss {
      cursor: pointer;
      padding: 0 0.25rem;
      margin-left: 1rem;
    }
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}