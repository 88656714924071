@import '../../../styles/variables.scss';

.project-details-container {
  .page-section {
    font-size: 1.25rem;
    margin: 2rem 0 1rem 0;
  }

  .table-wrapper {
    flex: none;
  }

  .config-buttons {
    display: flex;
    align-items: center;
    width: 100%;

    .btn {
      height: 2rem;
      min-height: 2rem;
      margin-right: 1rem;
      word-break: normal;
    }
  }
}

.config-separator {
  width: 100% !important;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  &:before {
    content: '';
    height: 2px;
    background-color: map-get($map: $colors, $key: 'utility');
    margin-right: 20px;
    flex-grow: 1;
  }

  &:after {
    content: '';
    height: 2px;
    background-color: map-get($map: $colors, $key: 'utility');
    margin-left: 20px;
    flex-grow: 1;
  }
}

.multi-select{
  margin-top: 5px;
}

.form{
  .singleRow{
    display: flex;
    .form-group {
      margin-right: 15px;
    }
  }
  .form-group {
    margin-top: 20px;
  }
  .schedules{
    h2{
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 1.3em;
    }
    .checkboxs{
      display: flex;
      .checkbox{
        margin-left: 10px;
      }
    }
  }

}

