  .paginator {
    .placeholder {
      margin: 0 0.5rem 0 0.25rem;
      font-size: 1.5rem;
    }

    button {
      font-size: 0.8rem;
      width: 40px;
      padding: 0;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }
    }
  }