@import '../../styles/variables.scss';

.panel {
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;

  .panel-header {
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    padding: 1rem;
    color: #ffffff;
    background: map-get($map: $colors, $key: 'brand');
    display: flex;
    justify-content: space-between;
  }

  .panel-body {
    flex: 1;
    padding: 1.5rem 1rem 1rem 1rem;
    overflow-y: auto;
  }
}