.modal-wrapper {
  visibility: hidden;
  opacity: 0;
  transition: all .2s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  
  &.visible {
    visibility: visible;
    opacity: 1;

    .backdrop .panel {
      transform: translate(0, 0);
    }
  }

  .backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);

    .panel {
      transition: all .2s ease-in-out;
      transform: translate(0, -50%);
    }

    .panel-content {
      flex: 1;
    }

    .actions {
      display: flex;
      margin-top: 1.5rem;
      justify-content: flex-end;

      button+button {
        margin-left: 1rem;
      }
    }
  }

  .modal-close {
    cursor: pointer;
    padding: 0 0.25rem;
  }
}