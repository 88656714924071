.form {
  width: 100%;
  margin-bottom: 1rem;
}

.dropZone{
  display: flex;
  justify-content: center;

  .dropBox{
    background-color: blue($color: #000000);
    height: 7vw;
    width: 100%;

    .dropActive {
      width: 100%;
      height: 100%;
      border: dashed grey 4px;
      // background-color: rgba(214, 213, 213, .8);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dropIcon {
      // border: dashed grey 4px;
      // background-color: rbga(255,255,255, .8);
      width: auto;
      height: 100%;
      border-radius: 50%;
      text-align: center;
      color: grey;
    }
    
    p {
      text-align: center;
      color: grey;
    }
  }
}

.dropZone:hover {
  cursor: pointer;
}

.uploadIcon{

  width: 70% !important;
  height: 100%;
}
