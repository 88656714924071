@import '../../styles/variables.scss';

$item-height: 55px;

.menu {
  box-shadow: $box-shadow;
  min-width: 225px;
  width: 225px;
  background: map-get($map: $colors, $key: 'background-dark');
  display: flex;
  flex-direction: column;
  transition: all .4s ease-out;

  .svg-inline--fa {
    width: 1rem;
  }

  &.collapsed {
    min-width: 50px;
    width: 50px;

    .brand {
      padding: 0.25rem;
    }

    .menu-item .label {
      opacity: 0;
      width: 0;
    }
  }

  .brand {
    min-height: $item-height;
    height: $item-height;
    padding: 0.5rem 1rem;;
    background: map-get($map: $colors, $key: 'brand');

    .logo {
      max-height: 50px;
      max-width: 100%;
    }
  }

  .menu-items {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .menu-item {
      min-height: $item-height;
      height: $item-height;
      color: #ffffff;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid darken($color: map-get($map: $colors, $key: 'background-dark'), $amount: 1);
      transition: all .4s ease-out;

      &.disabled {
        pointer-events: none;
        color: map-get($map: $colors, $key: 'text-light');

        svg path {
          fill: map-get($map: $colors, $key: 'text-light');
        }
      }

      .label {
        transition: opacity .4s ease-out;
        opacity: 1;
        white-space: nowrap;
      }

      &:hover {
        background: lighten($color: map-get($map: $colors, $key: 'background-dark'), $amount: 2);
      }

      &.active {
        background: lighten($color: map-get($map: $colors, $key: 'background-dark'), $amount: 5);
      }
    }

  .menu-toggle {
    margin-top: auto;
  }
}