@import '../../styles/variables.scss';

.form-group  {
  margin-bottom: 0.5rem;

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .input {
    width: 100%;
    position: relative;

    input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
      -webkit-appearance: none;
      width: 100%;
      font-size: 1rem;
      height: 40px;
      padding: 0 0.5rem;
      border-radius: $border-radius;
      border: 1px solid map-get($map: $colors, $key: 'utility');
      transition: all .2s ease-out;

      &.invalid {
        border-color: map-get($map: $colors, $key: 'error');
      }
    }

    .unit {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .errors {
    height: 15px;

    .error {
      margin-right: 0.25rem;
    }
  }
}