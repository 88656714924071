@import '../../styles/variables.scss';

.checkbox {
  &.no-spacing {
    margin: 0 !important;
    padding: 0 !important;
  }

  input[type="checkbox"] {
    position: absolute;
    top: -9999px;
    left: -9999px;

    & + label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      border-radius: $border-radius;
      border: 1px solid map-get($map: $colors, $key: 'utility');
      transition: all .1s ease;
    }

    &:hover + label:before {
      background: transparentize(map-get($map: $colors, $key: 'brand'), 0.75);
      border: 1px solid transparentize(darken(map-get($map: $colors, $key: 'brand'), 3), 0.75);
    }

    &:checked + label:before {
      content: '✓';
      color: #ffffff;
      text-align: center;
      border: 1px solid darken(map-get($map: $colors, $key: 'brand'), 3);
      background: map-get($map: $colors, $key: 'brand');
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
