.folating-button-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;

  .floating-buttons {
    position: relative;
    display: flex;
    flex-direction: column;

    .floating-button {
      position: unset;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}