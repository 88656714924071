.division-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .checkbox {
    margin-right: 2rem;
    margin-bottom: 0;

    .form-label {
      margin-bottom: 0;
    }
  }

  .dropdown {
    width: 300px;
    margin-bottom: 0;

    .form-label {
      display: none;
    }
    .errors {
      display: none;
    }
  }
}

.global-roles-wrapper {
  margin-top: 1rem;

  .global-roles {
    display: flex;
    flex-wrap: wrap;
  
    .checkbox {
      min-width: 25%;
      width: 25%;
    }
  }
}

.inline-form {
  display: flex;
  align-items: center;
  width: 50%;

  .form-group {
    flex: 1;
    padding-right: 0.5rem;
  }

  .btn+.btn {
    margin-left: 0.25rem;
  }
  .btn+.checkbox {
    margin-left: 1rem;
  }
}