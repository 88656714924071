@import '../../styles/variables.scss';

.form-group  {
  margin-bottom: 0.5rem;

  .form-label {
    margin-bottom: 0.5rem;
  }

  &.full-width {
    width: 100%;
  }
}