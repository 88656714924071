.title {
    margin: 20px;
}
.company {
    margin: 20px 0;
}
.success-message {
    margin-top: 20px;
    background:  green;
    color: white;
}
.company-logo {
    height: 150px;
}