$colors: (
  brand: #425e99,
  error: #DE5233,
  success: #659157,
  background: #fafafa,
  background-dark: #424242,
  utility: #acacac,
  text: #242424,
  text-light: #8c8c8c
);

$box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.3);
$button-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
$border-radius: 4px;