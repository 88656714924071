.box-container{
    margin: 4rem;
    display: flex;    
}

.banner-box{
    position: relative;    
    border-radius: 8px;
    padding: 5px;
    width: 13rem; 
    min-height: 13rem;    
    color: #ffffff;  
    margin-inline: 2rem;
}

.campaign{
    /* ff 3.6+ */
    background:-moz-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 217, 243, 1) 0%, rgba(11, 30, 51, 0.69) 100%); 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 217, 243, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
    /* opera 11.10+ */
    background:-o-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 217, 243, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0B1E33', endColorstr='#FFFF00', GradientType=1 );
    /* ie 10+ */
    background:-ms-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 217, 243, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
    /* global 94%+ browsers support */
    background:linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 217, 243, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
}

.template{
    /* ff 3.6+ */
    background:-moz-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 243, 191, 1) 0%, rgba(11, 30, 51, 0.69) 100%); 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 243, 191, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
    /* opera 11.10+ */
    background:-o-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 243, 191, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0B1E33', endColorstr='#FFFF00', GradientType=1 );
    /* ie 10+ */
    background:-ms-linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 243, 191, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
    /* global 94%+ browsers support */
    background:linear-gradient(149deg, rgba(255, 255, 0, 1) 0%, rgba(134, 243, 191, 1) 0%, rgba(11, 30, 51, 0.69) 100%);
}

.index-number{
    position: absolute; 
    top: 2rem; 
    left: 1rem; 
    font-size: 42px;    
}

.index-text{
    position: absolute;
    bottom: 3.0rem;
    left: 1rem;
    font-size: 34px;
}

.controls{
    position: absolute;
    bottom: 0.5rem;
    width: 95%;
    display: flex;
    justify-content: space-between;
    border-Top: 1px solid #ffffff;
    padding-top: 1rem
}

.control-button{
    cursor: pointer; 
    font-size: 18px,
}

.details-table{
    margin: 4rem;
}

.config-buttons {
    display: flex;
    align-items: center;
    width: 100%;

    .btn {
      height: 2rem;
      min-height: 2rem;
      margin-right: 1rem;
      word-break: normal;
    }
  }