@import '../../styles/variables.scss';

.table-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .table {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding-bottom: 1rem;

    .header {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid darken(map-get($map: $colors, $key: 'background'), 5);
      height: 50px;
      min-height: 50px;
      background: #ffffff;
    }

    .body {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: scroll;
      
      .row {
        background: #ffffff;
        display: flex;
        font-size: 0.8rem;
        height: 40px;
        min-height: 40px;

        &.muted {
          color: map-get($map: $colors, $key: 'text-light')
        }

        &.clickable {
          cursor: pointer;

          &:hover {
            background: darken(map-get($map: $colors, $key: 'background'), 5);
          }
        }

        &:nth-child(2n) {
          background: map-get($map: $colors, $key: 'background');
        }
      }
    }

    .cell {
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      overflow-wrap: break-word;
      word-break: break-all;

      &.actions {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        min-width: 100px;

        &.hidden {
          visibility: hidden;
        }
        
        button {
          font-size: 0.8rem;
          width: 40px;
          padding: 0;

          &:not(:last-child) {
            margin-right: 0.25rem;
          }

          &.visible {
            visibility: visible;
          }
        }
      }
    }
  }
}