@import '../../styles/variables.scss';

.form-group {
  margin-bottom: 0.5rem;

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  select {
    -webkit-appearance: none;
    font-size: 1rem;
    height: 40px;
    padding: 0 0.5rem;
    display: block;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid map-get($map: $colors, $key: 'utility');
    transition: all .2s ease-out;
    background-color: #ffffff;
    background-image: url('~/public/assets/select_bg.svg');
    background-repeat: no-repeat;
    background-position: right 0.75rem top 50%;
    background-size: 0.75rem;

    &.invalid {
      border-color: map-get($map: $colors, $key: 'error');
    }
  }

  .errors {
    height: 15px;
  }
}