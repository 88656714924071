.voiceFiles{
  h2{
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 1.3em;
  }
  .singleRow{
    align-items: center;
  }

  button {
    margin-top: 25px;
    margin-right: 50px;
  }
}

.dropbox {
  display: flex;
}

.file-dropbox {
  flex-grow: 2;
  background-color: darkgrey;
  padding: 5px;
  border-radius: 5px;
}

.dropbox-detail{
  flex-grow: 3;
  display:flex;
  justify-content: flex-start; 
  align-items: flex-start;
  flex-direction: column;
  margin-left: 5px;

}

.file-list{
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 8px;

  p, div{
    margin-right: 15px
  }
  .file-detail {
    display: flex;
    
    span {
      font-size: 16px;
      color: #555;
    }
  }

  .deleteIcon, .deleteIcon:hover{
    width: 1em;
    color: #DE5233;
    margin-left: 8px;
  }
  .deleteIcon:hover{
    cursor: pointer;
    
  }
}

.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(103, 58, 183, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.fileName { 
  margin-right: 10px;
}