@import '../../styles/variables.scss';

@mixin btn-icon-color($variant, $addHover: false) {
  color: map-get($map: $colors, $key: $variant);
  padding: 0 0.5rem;
  background: transparent;

  &:hover {  
    @if $addHover {
      background: map-get($map: $colors, $key: $variant);
      color: #ffffff;
    }
  }
}

.btn {
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border-radius: $border-radius;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  border:none;
  transition: all .2s ease-out;

  &.disabled, &[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: .6;
  }

  &-default {
    color: map-get($map: $colors, $key: 'text');
    background: map-get($map: $colors, $key: 'background');

    &:hover {
      background: darken(map-get($map: $colors, $key: 'background'), 5);
    }
  }

  &-white {
    color: map-get($map: $colors, $key: 'text');
    background: #ffffff;

    &:hover {
      background: darken(map-get($map: $colors, $key: 'background'), 5);
    }
  }

  &-primary {
    color: #ffffff;
    background: map-get($map: $colors, $key: 'brand');

    &:hover {
      background: darken($color: map-get($map: $colors, $key: 'brand'), $amount: 5)
    }
  }

  &-error {
    background: map-get($map: $colors, $key: 'error');
    color: #ffffff;

    &:hover {
      background: darken($color: map-get($map: $colors, $key: 'error'), $amount: 5)
    }
  }

  &-with-icon {
    .label {
      margin-left: 0.5rem;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.large-icon svg{
    width: 2rem;
    height: 2rem;
  }

  &.flex-column {
    height: auto;
    padding: 0.5rem;

    .label {
      margin: 0.25rem 0 0 0;
    }
  }

  &-icon {
    &-default {
      @include btn-icon-color('text');
      &:hover {  
        background: darken(map-get($map: $colors, $key: 'background'), 5);
      }
    }

    &-primary {
      @include btn-icon-color('brand', true);
    }

    &-error {
      @include btn-icon-color('error', true);
    }
  }
}